var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{ref:"nav",staticClass:"nav container",attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('button',{ref:"navTitle",staticClass:"nav__title serif",attrs:{"tabindex":"0","aria-label":"to top"},on:{"click":function($event){_vm.scrollTo(0), _vm.$nuxt.$emit('toggle-menu', false)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("ia")])]),_vm._v(" "),_c('div',{ref:"navSections",staticClass:"nav__sections"},[_c('div',{ref:"navSectionsCircle",class:{
        nav__sections__circle: true,
        'nav__sections__circle--hidden': !_vm.isShowingCurrentSection,
      }}),_vm._v(" "),_c('ul',{ref:"navSectionsList",staticClass:"nav__sections__list",style:({ '--sections-length': _vm.sections.length })},_vm._l((_vm.sections),function(section,key){return _c('li',{key:key,ref:"navSectionsListSections",refInFor:true,class:{
          nav__sections__list__section: true,
          'nav__sections__list__section--active': key === _vm.currentSection,
          'nav__sections__list__section--non-active': key !== _vm.currentSection && _vm.isShowingCurrentSection,
        },attrs:{"role":"button","tabindex":"0"},on:{"click":function($event){return _vm.scrollTo(section.scrollTo)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.scrollTo(section.scrollTo)}}},[_vm._v("\n        "+_vm._s(section.label)+"\n      ")])}),0)]),_vm._v(" "),_c('button',{ref:"navMenuButton",staticClass:"nav__menu-button",attrs:{"tabindex":"0","aria-label":"menu button"},on:{"click":_vm.toggleMenu,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.stopPropagation();},"pointerenter":_vm.hoverAnimation,"pointerleave":_vm.idleAnimation}},[_c('MenuIconSVG',{ref:"navMenuButtonSVG",attrs:{"aria-hidden":"true"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
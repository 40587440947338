//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuIconSVG from '~/assets/img/menu-icon.svg?inline'

const SVG_SIZE = 20
const SVG_LINES_PADDING = 4

export default {
  components: { MenuIconSVG },
  props: { currentSection: { type: Number, required: true, default: 0 } },
  data: () => ({
    prefersReducedMotion: false,
    isMenuActive: false,
    isShowingCurrentSection: false,
    sections: [
      { label: 'Home', scrollTo: 0 },
      { label: 'Galería', scrollTo: '.works' },
      { label: 'Imágenes Ausentes', scrollTo: '.about' },
      { label: 'Contacto', scrollTo: '.contact' },
    ],
  }),
  watch: {
    currentSection(val) {
      if (this.prefersReducedMotion) return

      const { navSections, navSectionsListSections, navSectionsCircle } = this.$refs

      // NOTE: when desktop navigation is hidden
      // it doesn't have any width or height
      const sectionsHeight = navSections.clientHeight || 94

      const oneHeight = 1.5 + sectionsHeight / navSectionsListSections.length

      this.$gsap.to(navSectionsCircle, {
        '--top-offset': val * oneHeight,
        ease: 'back.out',
        duration: 0.3,
      })
    },
    isMenuActive(val) {
      if (val) this.closeAnimation()
      else this.idleAnimation()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

      const { navTitle } = this.$refs

      const gsap = this.$gsap
      const ScrollTrigger = this.$ScrollTrigger

      gsap.fromTo(
        navTitle,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          scrollTrigger: {
            trigger: '.header',
            start: `60% top+=60px`,
            end: `bottom top+=60px`,
            scrub: true,
          },
        }
      )

      ScrollTrigger.create({
        trigger: 'header',
        start: 'top+=35% top+=80px',
        end: 'top+=35% top+=80px',
        onEnter: () => (this.isShowingCurrentSection = true),
        onLeaveBack: () => (this.isShowingCurrentSection = false),
      })

      this.$nuxt.$on('toggle-menu', (bool) => {
        if (typeof bool === 'boolean') this.isMenuActive = bool
        else this.isMenuActive = !this.isMenuActive
      })
    },
    toggleMenu() {
      this.$nuxt.$emit('toggle-menu')
    },
    closeAnimation() {
      const lines = this.$refs.navMenuButtonSVG.children

      const tl = this.$gsap.timeline({ defaults: { ease: 'back.out' } })

      const line0Attrs = {
        x1: SVG_SIZE - SVG_LINES_PADDING,
        y1: SVG_LINES_PADDING,
        x2: SVG_LINES_PADDING,
        y2: SVG_SIZE - SVG_LINES_PADDING,
      }
      const line1Attrs = {
        x1: SVG_LINES_PADDING,
        y1: SVG_LINES_PADDING,
        x2: SVG_SIZE - SVG_LINES_PADDING,
        y2: SVG_SIZE - SVG_LINES_PADDING,
      }

      if (this.prefersReducedMotion) {
        tl.to(lines, { opacity: 0 })
        tl.set(lines[0], { attr: line0Attrs })
        tl.set(lines[1], { attr: line1Attrs })
        tl.to(lines, { opacity: 1 })
      } else {
        tl.to(lines[0], { attr: line0Attrs })
        tl.to(lines[1], { attr: line1Attrs }, '<')
      }
    },
    idleAnimation(ev) {
      // NOTE: this will work, because of pointer leave event
      // if toggle menu even is fired, then ev will be undefined
      if (this.isMenuActive || ev) return

      const lines = this.$refs.navMenuButtonSVG.children

      const tl = this.$gsap.timeline({ defaults: { ease: 'back.out' } })

      const line0Attrs = { x1: 0.25, y1: 7.75, x2: 19.75, y2: 7.75 }
      const line1Attrs = { x1: 5.25, y1: 11.75, x2: 19.75, y2: 11.75 }

      // NOTE: just reverting everything to default

      if (this.prefersReducedMotion) {
        tl.to(lines, { opacity: 0 })
        tl.set(lines[0], { attr: line0Attrs })
        tl.set(lines[1], { attr: line1Attrs })
        tl.to(lines, { opacity: 1 })
      } else {
        tl.to(lines[0], { attr: line0Attrs })
        tl.to(lines[1], { attr: line1Attrs }, '<')
      }
    },
    hoverAnimation() {
      if (this.isMenuActive || this.prefersReducedMotion) return

      const lines = this.$refs.navMenuButtonSVG.children

      // NOTE: just setting first x coordinate of two lines to padding
      this.$gsap.to(lines, {
        attr: { x1: SVG_LINES_PADDING },
        ease: 'back.out',
      })
    },
    scrollTo(location) {
      const gsap = this.$gsap
      gsap.to(window, {
        scrollTo: {
          y: location,
          // offsetY: 50,
        },
        duration: 2,
        ease: 'power3.inOut',
      })
    },
  },
}
